import { application } from './application'

import AnalyticsController from './analytics_controller'
import AutoSubmitController from './auto_submit_controller'
import BulkSelectionController from './bulk_selection_controller'
import CodeEditorController from './code_editor_controller'
import DialogController from './dialog_controller'
import MdmTypeSelectorController from './mdm_type_selector_controller'
import RemoveElementController from './remove_element_controller'
import SubmitOnChangeController from './submit_on_change_controller'
import TableController from './table_controller'
import TabsController from './tabs_controller'

application.register('analytics', AnalyticsController)
application.register('auto-submit', AutoSubmitController)
application.register('bulk-selection', BulkSelectionController)
application.register('code-editor', CodeEditorController)
application.register('dialog', DialogController)
application.register('mdm-type-selector', MdmTypeSelectorController)
application.register('remove-element', RemoveElementController)
application.register('submit-on-change', SubmitOnChangeController)
application.register('table', TableController)
application.register('tabs', TabsController)
