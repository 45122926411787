import { Dropdown } from 'flowbite'
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['button', 'menu', 'form']

  connect () {
    // eslint-disable-next-line no-new
    new Dropdown(
      this.menuTarget,
      this.buttonTarget,
      {
        onHide: () => {
          this.formTarget.requestSubmit()
        }
      },
      {
        id: 'dropdownMenu',
        override: true
      }
    )
  }
}
