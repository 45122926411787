import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'
export default class extends Controller {
  static targets = ['checkbox', 'deviceSerialNumbers', 'selectAll', 'selectedCount']

  static values = {
    newResourceUrl: String
  }

  toggleAll (event) {
    for (const checkbox of this.checkboxTargets) {
      checkbox.checked = event.target.checked
    }

    this.changed()
  }

  changed () {
    const selected = this.checkboxTargets.filter(checkbox => checkbox.checked)
    this.element.classList.toggle('selected', selected.length > 0)

    this.#setDeviceSerialNumbers(selected)
    this.#setSelectedCount(selected)
    this.#setSelectAll(selected)
  }

  commit (event) {
    // Placeholder selected, do nothing
    if (event.target.value === '') return

    if (event.target.value === 'new') {
      this.#redirectToNewResourceUrl()
    } else {
      event.target.form.requestSubmit()
    }
  }

  #setDeviceSerialNumbers (selected) {
    this.deviceSerialNumbersTarget.value = selected.map(checkbox => checkbox.dataset.serialNumber).join(',')
  }

  #setSelectedCount (selected) {
    this.selectedCountTarget.innerHTML = `${this.#pluralize(selected.length, 'device')} selected`
  }

  #setSelectAll (selected) {
    this.selectAllTarget.checked = selected.length === this.checkboxTargets.length
  }

  #redirectToNewResourceUrl () {
    Turbo.visit(`${this.newResourceUrlValue}?device_serial_numbers=${this.deviceSerialNumbersTarget.value}`)
  }

  #pluralize (count, word) {
    return `${count} ${word}${count === 1 ? '' : 's'}`
  }
}
