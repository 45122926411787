import * as Sentry from '@sentry/browser'

const meta = (name) =>
  document.querySelector(`meta[name=${name}]`)?.content || ''

const browserTracing = Sentry.browserTracingIntegration()
const replay = Sentry.replayIntegration()

document.addEventListener('turbo:load', function () {
  if (meta('uid')) {
    Sentry.setUser({
      id: meta('uid'),
      email: meta('email'),
      username: meta('username')
    })
  }
  window.sentry = Sentry.init({
    dsn: meta('client_dsn'),
    environment: meta('environment'),
    release: meta('client_release'),
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    ignoreErrors: [
    ],
    integrations: [
      browserTracing,
      replay
    ]
  })
  if (meta('sentry_error_id')) {
    Sentry.showReportDialog({ eventId: meta('sentry_error_id') })
  }
  if (window.location.hash === '#test_sentry_error') {
    setTimeout(() => {
      throw new Error('Test Sentry error!')
    }, 0)
  }
})
