const meta = (name) =>
  document.querySelector(`meta[name=${name}]`)?.content || ''

document.addEventListener('turbo:load', function () {
  // configured in and copy-pasted from:
  // https://workbrew.unthread.io/dashboard/settings/in-app-chat
  (function (w, d, t) {
    w.$unthreadInAppChatSettings = {
      baseUrl: 'https://workbrew.unthread.io',
      widgetId: 'daa5fe69-d26a-41ed-b886-fdddd18cd52c'
    }

    const u = function () {
      u.c(arguments)
    }
    u.q = []
    u.c = function (arguments_) {
      u.q.push(arguments_)
    }

    if (!w.$unthread) {
      w.$unthread = {}
    }

    w.$unthread.inAppChat = u

    const g = d.createElement(t); const s = d.getElementsByTagName(t)[0]
    g.src = w.$unthreadInAppChatSettings.baseUrl + '/widget/js/wrapper.js'
    g.defer = true
    g.async = true
    s.parentNode.insertBefore(g, s)

    // end copy-paste deminified
    if (meta('uid')) {
      window.$unthread.inAppChat('start', {
        user: {
          name: meta('username'),
          email: meta('email')
        }
      })
    }
  })(window, document, 'script')
})
