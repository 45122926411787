document.addEventListener('turbo:frame-missing', event => {
  const environment = document.querySelector('meta[name="environment"]')?.content

  if (environment === 'production') {
    const { response, visit } = event.detail

    // Don't show the normal "Content Missing" error.
    event.preventDefault()

    // Send a error to Sentry so that we know this this frame is missing.
    const statusCode = response.statusCode
    const id = event.target.id
    window.sentry.captureException(new Error(`The response (${statusCode}) did not contain the expected <turbo-frame id="${id}">.`))

    // But actually just visit the URL instead of showing a error.
    visit(response.url)
  }

  // Do nothing in non-production environments.
})
